import React, { useRef, useState, useEffect } from 'react';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import TextAndImageV2 from '@beelineloans/cx-library/src/components/layout/panels/TextAndImageV2';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import Logo from '@beelineloans/cx-library/src/images/logos/beeline-logo.png';
import CheckList from '@beelineloans/cx-library/src/images/checklist.png';
import { ButtonText, Button } from '@beelineloans/cx-library/src/components/buttons';
import { TextField, EmailField } from '@beelineloans/cx-library/src/components/forms/fields';
import styled from 'styled-components';
import Container from '@beelineloans/cx-library/src/components/layout/ContainerV2';
import { isEmailValid } from '@beelineloans/cx-library/src/utils/helpers';
import { post } from '@beelineloans/cx-library/src/utils/fetch';
import { pushFormError } from '@beelineloans/cx-library/src/utils/dataLayer';
import { ErrorMessage, SuccessMessage } from '@beelineloans/cx-library/src/components/typography';
import { HeaderlessTemplate } from '../Template';

const LogoLink = styled(ButtonText)``;
const From = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 70px;
  justify-content: space-between;
`;
const NameWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;
const ButtonWrap = styled.div`
  margin-top: 20px;
`;

const BeelineTitle = () => {
  const formRef = useRef(null);
  const [errorFields, setErrorFields] = useState({});
  const [canSubmit, setCanSubmit] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [failMessage, setFailMessage] = useState();
  const [loading, setLoading] = useState(false);

  const headline = 'Get your login details for title quote.';
  const subHeadline = `To set you up, all we need is your full name and email address.
      Then we'll create a login for ResWare (our quoting system), email the details to you and you can see for yourself how competitive we are!`;

  const getSearchFields = () => {
    const searchFields = {
      firstName: formRef.current.firstName.value?.trim(),
      lastName: formRef.current.lastName.value?.trim(),
      email: formRef.current.email.value?.trim()
    };
    return searchFields;
  };

  const validateField = (field, searchFields) => {
    let error;
    const allFields = searchFields ?? getSearchFields();
    setFailMessage();
    if (field === 'firstName' && allFields.firstName === '') {
      error = 'Please enter your first name';
    }
    if (field === 'lastName' && allFields.lastName === '') {
      error = 'Please enter your last name';
    }
    if (field === 'email' && allFields.email === '') {
      error = 'Please enter your email address';
    } else if (field === 'email' && !isEmailValid(allFields.email)) {
      error = 'Please enter a valid email address';
    }

    const updatedErrorFields = { ...errorFields, [field]: error };
    setErrorFields(updatedErrorFields);
    return !error;
  };

  const validate = () => {
    const searchFields = getSearchFields();
    let valid = true;
    if (!validateField('firstName', searchFields)) valid = false;
    if (!validateField('lastName', searchFields)) valid = false;
    if (!validateField('email', searchFields)) valid = false;
    return valid;
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const formName = 'beeline_title_quote_registration';
    const postUrl = `${process.env.GATSBY_API}/contact`;
    const searchFields = getSearchFields();

    setLoading(true);

    if (validate()) {
      const headers = {
        'x-api-key': process.env.GATSBY_API_KEY
      };
      const data = {
        type: 'WEBFORM',
        form: formName,
        ...searchFields
      };

      post(postUrl, data, headers)
        .then((res) => {
          if (res.data?.message === 'Validation') {
            setFailMessage(`Please fix the following errors: ${res.data.errors.map((errs) => errs.message).join(', ')}`);
          } else {
            setSubmitted(true);
          }
          setLoading(false);
        })
        .catch(() => {
          pushFormError({
            form: formName,
            endpoint: postUrl,
            fields: searchFields
          });
          setFailMessage("Oops. We couldn't send your request. Please try again.");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!submitted) {
      const hasNoValue = Object.values(getSearchFields()).some((field) => field === undefined || field.trim() === '');
      const hasError = Object.values(errorFields).some((field) => field !== undefined);
      setCanSubmit(!hasNoValue && !hasError);
    }
  }, [errorFields]);

  return (
    <HeaderlessTemplate>
      <SEO path={CONST.LINKS.TITLE.HOME} title={headline} description={subHeadline} />
      <Container paddingBottom={0}>
        <LogoLink to={CONST.LINKS.TITLE.HOME}>
          <img src={Logo} width="130" alt="Beeline Title" />
        </LogoLink>
      </Container>
      <TextAndImageV2
        h2={headline}
        image={{
          src: CheckList,
          mobileWidth: '315px',
          title: { headline },
          desktopWidth: '518px',
          alignment: 'right'
        }}
        left={false}
      >
        {subHeadline}
        {submitted && <SuccessMessage>Thanks for your interest in Beeline Title. We&apos;ll be in touch shortly.</SuccessMessage>}
        {!submitted && (
          <From ref={formRef}>
            <NameWrap>
              <TextField placeholder="First name" id="firstName" error={errorFields.firstName} onChange={() => validateField('firstName')} />
              <TextField placeholder="Last name" id="lastName" error={errorFields.lastName} onChange={() => validateField('lastName')} />
            </NameWrap>
            <EmailField placeholder="your@company.com" id="email" error={errorFields.email} onChange={() => validateField('email')} />
            <ButtonWrap>
              <Button onClick={onFormSubmit} disabled={!canSubmit} loading={loading}>
                Submit
              </Button>
            </ButtonWrap>
            {failMessage && <ErrorMessage>{failMessage}</ErrorMessage>}
          </From>
        )}
      </TextAndImageV2>
    </HeaderlessTemplate>
  );
};

export default BeelineTitle;
